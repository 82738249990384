import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Discografia = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Discografia"
        titleTemplate="%s | Eduardo Ceballos"
        description="Discografia"
        canonical="https://eduardoceballos.com/discografia"
        openGraph={{
          url: `https://eduardoceballos.com/discografia`,
          title: `Discografia`,
          description: `Discografia`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/discografia.jpg`,
              alt: `Discografia`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>DISCOGRAFÍA</h1>
      <center>
        <p>Eduardo Ceballos ha grabado:</p>
      </center>
      <div className="discos">
        <Link to="/discografia/la-palabra">
          <center>
            <img
              src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdlapalabra_09e5996d44.jpg"
              alt=""
            />
            <p>La Palabra - 2006</p>
          </center>
        </Link>
        <Link to="/discografia/es-primavera">
          <center>
            <img
              src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdesprimavera_490986f163.jpg"
              alt=""
            />
            <p>Es Primavera - 2015</p>
          </center>
        </Link>
        <Link to="/discografia/per-saecula-saeculorum-amen">
          <center>
            <img
              src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdpersaeculasaeculorumamen_f4e170abd4.jpg"
              alt=""
            />
            <p>Per Saecula Saeculorum Amen - 2015</p>
          </center>
        </Link>
      </div>
      <center>
        <p>Y ha grabado con Cholonga Navarro:</p>
      </center>
      <div className="discos">
        <Link to="/discografia/frutos-de-la-memoria">
          <center>
            <img
              src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdfrutosdelamemoria_9f2deafd02.jpg"
              alt=""
            />
            <p>Frutos de la Memoria</p>
          </center>
        </Link>
      </div>
    </Layout>
  )
}

export default Discografia
